import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  onPostComplete(event) {
    const response = JSON.parse(event.detail[0].response)
    const type = response.result === 'success' ? 'success' : 'danger'

    setTimeout(() => {
      this.showMessage(response.msg, type)
    }, 1000)
  }

  saveProfile() {
    const button = this.sourceTarget
    const form = document.getElementById('user-profile-form')

    button.disabled = true
    Rails.fire(form, 'submit');

    setTimeout(() => {
      button.disabled = false
    }, 5000)
  }

  showMessage(msg, type = 'success') {
    const alert = document.querySelector(`.alert--${type}`)
    const span = alert.querySelector('.msg')
    span.innerHTML = msg
    alert.classList.remove('d-none')
    setTimeout(() => {
      alert.classList.add('d-none')
    }, 5000)
  }
}
