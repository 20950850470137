import { createApp } from 'vue';
import Filter from '../components/filter/Filter.vue'

import store from '../vuex'
import { Tab } from 'bootstrap';

// Stimulus JS
import { application } from "../../controllers/application";
import StickerRequestController from "../../controllers/sticker_request_controller";
import UserProfileController from "../../controllers/user_profile_controller";

export const UserProfileShowView = {

  init() {
    // const filter = document.getElementById('filter-wrapper')
    // const searchParams = JSON.parse(filter.dataset.searchParams)
    //
    // let ssrInit = true
    // let personalFilter = undefined
    // let utilityFilter = undefined
    //
    // document.addEventListener('show.bs.tab', function(e) {
    //   if(e.target.id === 'nav-personal-tab') {
    //     if(personalFilter) {
    //       personalFilter.initialize()
    //     } else {
    //       personalFilter = createApp(Filter, { redirect: false, baseUrl: '', modelType: 'personal', style: 'user_profile', searchParams })
    //         .use(store)
    //         .mount('#filter-wrapper-personal')
    //       if(ssrInit) {
    //         personalFilter.loadFilterOptions()
    //       } else {
    //         personalFilter.initialize()
    //       }
    //     }
    //     window.filter = personalFilter
    //   } else if(e.target.id === 'nav-utility-tab') {
    //     if(utilityFilter) {
    //       utilityFilter.initialize()
    //     } else {
    //       utilityFilter = createApp(Filter, { redirect: false, baseUrl: '', modelType: 'utility', style: 'user_profile', searchParams })
    //         .use(store)
    //         .mount('#filter-wrapper-utility')
    //       if(ssrInit) {
    //         utilityFilter.loadFilterOptions()
    //       } else {
    //         utilityFilter.initialize()
    //       }
    //     }
    //     window.filter = utilityFilter
    //   }
    // })

    const settingsTab = new Tab(document.querySelector('#nav-settings-tab'))
    const personalTab = new Tab(document.querySelector('#nav-offers-tab'))
    if ((location.hash !== null && location.hash === '#nav-settings-tab')) {
      settingsTab.show()
    } else {
      personalTab.show()
    }

    UserProfileShowView.initStimulus()
  },

  initStimulus() {
    application.register("sticker_request", StickerRequestController)
    application.register('user_profile', UserProfileController)
  }

}
