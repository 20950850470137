<template>
  <div :class="{ 'bg-info-lighter': this.lengthCounter === 0, [this.cssStyle]: true }">
    <label for="next-info" class="form-label">{{ label }}
      <i class="icon icon--sm icon-question-circle" data-bs-toggle="tooltip" role="tooltip" aria-label="tooltip text" data-bs-placement="top" title="" data-bs-original-title=""></i>
    </label>
    <div v-if="subLabel.length > 0" class="form-label__info-text" v-html="subLabel"></div>
    <textarea v-model="modelValue"
              id="next-info"
              class="form-control form-control-lg"
              :maxlength="maxLength"
              :rows="rowsCount"
              :placeholder="placeholder"
              @input="$emit('update:modelValue', $event.target.value)">
    </textarea>
    <div v-if="showLengthCounter && maxLength > 0" class="form-counter" data-counter="next-info">{{ `${lengthCounter}/${maxLength}` }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormTextArea',

  props: {
    inputType: {
      type: String,
      default: "Text"
    },

    modelValue: {
      type: String,
      default: undefined
    },

    label: {
      type: String,
      required: true
    },

    subLabel: {
      type: String,
      default: ""
    },

    maxLength: {
      type: Number,
      default: undefined
    },

    showLengthCounter: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: "Uveďte"
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    cssStyle: {
      type: String,
      default: ""
    },

    rowsCount: {
      type: Number,
      default: 3
    }
  },

  computed: {
    lengthCounter() {
      return this.modelValue ? this.modelValue.length : 0
    }
  }

}
</script>