import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  createRegistration() {
    const button = this.sourceTarget
    const form = document.getElementById('user-signin-form')
    grecaptcha.ready(function() {
      grecaptcha.execute('6Ld74_UmAAAAAB38L7JLS1yh4TZbxLc1elXppWQU', {action: 'create_user_registration'}).then(function (token) {
        button.disabled = true
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", "recaptcha_token");
        input.setAttribute("value", token);
        form.appendChild(input)
        form.submit()
        setTimeout(() => {
          button.disabled = false
        }, 5000)
      })
    })
  }
}
