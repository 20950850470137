import { Toast, Popover } from 'bootstrap';
import '../js/header.js'; //shared all
import '../js/go-top.js'; //shared all
import '../js/tooltip.js'; //shared all
import '../js/cookieconsent.js'; //shared all
import '../js/cookieconsent-settings'; //shared all
import '../js/form-counter.js'; //shared all
import '../js/input-mask.js'; //shared all

import '../js/sorting.js'; //homepage, private offer, seller-detail, multiseller-detail,seller-profile
import '../js/filter-selection.js'; //homepage, private offer
import '../js/user-leaving.js'; //user-leaving
import '../js/image-checkbox.js'; //edit offer, edit inquiry, modals, homepage, private-offer
import '../js/content-radio.js'; //edit offer, edit inquiry, modals, homepage, private-offer
import '../js/litepicker.js'; //seller-profile
import '../js/gallery-list.js'; //edit offer, edit inquiry, modals, homepage, private-offer
import '../js/cropper.js'; //edit offer, edit inquiry, modals, homepage, private-offer
import '../js/car-gallery.js' //car-detail
import '../js/card-check.js'; //seller-profile

import { VideoModal } from '../js/video-modal.js';
import { ShareModal } from '../js/share-modal.js';
import { StickerModal } from '../js/sticker-modal.js';
import { AcorrdionService } from '../js/accordion-service.js';
import { HomepageView } from './views/HomepageView.js';
import {
  AdvertisementEditView,
  AdvertisementIndexView,
  AdvertisementNewView,
  AdvertisementShowView,
} from './views/AdvertisementView.js';
import { UserProfileShowView } from './views/UserProfileView';
import { UserAdvertisementShowView } from './views/UserAdvertisementView';
import { PrivateAdvertisementIndexView } from './views/PrivateAdvertisementView';
import { ProfessionalUserShowView } from './views/ProfessionalUserView';

const UTIL = {
  exec: function (controller, action) {
    var ns = SITENAME;
    action = action === undefined ? 'init' : action;

    if (
      controller !== '' &&
      ns[controller] &&
      typeof ns[controller][action] == 'function'
    ) {
      ns[controller][action]();
    }
  },

  init: function () {
    const body = document.body,
      controller = body.getAttribute('data-controller'),
      action = body.getAttribute('data-action');

    UTIL.exec('common');
    UTIL.exec(controller);
    UTIL.exec(controller, action);
    UTIL.exec('onload_common');
  },
};

const SITENAME = {
  // EXAMPLE of commons
  //
  // common: {
  //   init: function() {
  //   }
  // },

  onload_common: {
    init: function () {
      ShareModal.init();
      AcorrdionService.init();
    },
  },

  homepage: {
    index() {
      HomepageView.init();
      VideoModal.init();
    },
  },

  advertisements: {
    index() {
      AdvertisementIndexView.init();
    },

    show() {
      AdvertisementShowView.init();
    },

    new() {
      AdvertisementNewView.init();
    },

    edit() {
      AdvertisementEditView.init();
    }
  },

  private_advertisements: {
    index() {
      PrivateAdvertisementIndexView.init();
    },
  },

  user_profiles: {
    show() {
      UserProfileShowView.init();
      StickerModal.init();
    },
  },

  user_advertisements: {
    show() {
      UserAdvertisementShowView.init();
      StickerModal.init();
      VideoModal.init();
    },
  },

  professional_users: {
    show() {
      //ProfessionalUserShowView.init();
    },
  },
};

document.addEventListener('DOMContentLoaded', () => {
  UTIL.init();
});
