import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"
import {isEmpty, isNumber} from "lodash";
import FormService from "../src/services/formService";

export default class extends Controller {
  static targets = [ "source" ]

  onPostComplete(event) {
    const response = JSON.parse(event.detail[0].response)
    const alertType = response.result === 'success' ? 'success' : 'danger'

    setTimeout(() => {
      this.showMessage(response.msg, alertType)
    }, 1000)

    setTimeout(() => {
      this.closeModal()
    }, 5000)
  }

  sendMessage() {
    const button = this.sourceTarget
    const form = document.getElementById('sticker-request-form')
    const validationErrors = this.validate()
    if(validationErrors.length === 0) {
      button.disabled = true
      Rails.fire(form, 'submit');
      setTimeout(() => {
        button.disabled = false
      }, 5000)
    }
  }

  validate() {
    const errors = []
    errors.push(...this.validateAdvertisement())
    errors.push(...this.validateName())
    errors.push(...this.validateStreet())
    errors.push(...this.validateCity())
    errors.push(...this.validateZip())
    return errors
  }

  validateAdvertisement() {
    const errors = []
    const form = document.getElementById('sticker-request-form')
    const select = form.querySelector('#advertisement-select')
    if(!select || select?.value === 'choose' || !isNumber(parseInt(select?.value))) errors.push("Vyberte inzerát, který chcete podpořit.")
    FormService.clearInputState(select)
    if(errors.length > 0) {
      FormService.setInputAsInvalid(select, errors[0])
    } else {
      FormService.setInputAsValid(select)
    }
    return errors
  }

  validateName() {
    const errors = []
    const form = document.getElementById('sticker-request-form')
    const nameInput = form.querySelector('#sticker-request-name')
    if(!nameInput || nameInput?.value.length < 3) errors.push("Jméno je příliš krátké.")
    FormService.clearInputState(nameInput)
    if(errors.length > 0) {
      FormService.setInputAsInvalid(nameInput, errors[0])
    } else {
      FormService.setInputAsValid(nameInput)
    }
    return errors
  }

  validateStreet() {
    const errors = []
    const form = document.getElementById('sticker-request-form')
    const streetInput = form.querySelector('#sticker-request-street')
    if(!streetInput || streetInput?.value.length < 5) errors.push("Ulice je příliš krátká.")
    FormService.clearInputState(streetInput)
    if(errors.length > 0) {
      FormService.setInputAsInvalid(streetInput, errors[0])
    } else {
      FormService.setInputAsValid(streetInput)
    }
    return errors
  }

  validateCity() {
    const errors = []
    const form = document.getElementById('sticker-request-form')
    const cityInput = form.querySelector('#sticker-request-city')
    if(!cityInput || cityInput?.value.length < 3) errors.push("Město je příliš krátké.")
    FormService.clearInputState(cityInput)
    if(errors.length > 0) {
      FormService.setInputAsInvalid(cityInput, errors[0])
    } else {
      FormService.setInputAsValid(cityInput)
    }
    return errors
  }

  validateZip() {
    const errors = []
    const form = document.getElementById('sticker-request-form')
    const zipInput = form.querySelector('#sticker-request-zip')
    if(!zipInput || zipInput?.value.toString().length < 5 || zipInput?.value.toString().length > 5) errors.push("PSČ je ve špatném formátu.")
    FormService.clearInputState(zipInput)
    if(errors.length > 0) {
      FormService.setInputAsInvalid(zipInput, errors[0])
    } else {
      FormService.setInputAsValid(zipInput)
    }
    return errors
  }

  showMessage(msg, type = 'success') {
    const modal = document.getElementById('advertisementsStickerModal')
    const alert = modal.querySelector(`.alert--${type}`)
    const msgWrapper = alert.querySelector(`.msg`)
    msgWrapper.innerHTML = msg
    alert.classList.remove('d-none')
    setTimeout(() => {
      alert.classList.add('d-none')
    }, 5000)
  }

  closeModal() {
    const modal = document.getElementById('advertisementsStickerModal')
    modal.querySelector('.btn-close').click()
  }
}
