import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  onPostSuccess(event) {
    const response = JSON.parse(event.detail[0].response)

    setTimeout(() => {
      this.showMessage(response.msg, 'success')
    }, 1000)

    setTimeout(() => {
      this.closeModal()
    }, 5000)
  }

  onPostError(event) {
    const response = JSON.parse(event.detail[0].response)

    setTimeout(() => {
      this.showMessage(response.msg, 'danger')
    }, 1000)

    setTimeout(() => {
      this.closeModal()
    }, 5000)
  }

  sendMessage() {
    const button = this.sourceTarget
    const form = document.getElementById('question-message-form')
    grecaptcha.ready(function() {
      grecaptcha.execute('6Ld74_UmAAAAAB38L7JLS1yh4TZbxLc1elXppWQU', {action: 'send_question_message'}).then(function (token) {
        button.disabled = true
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", "recaptcha_token");
        input.setAttribute("value", token);
        form.appendChild(input)
        Rails.fire(form, 'submit');
        setTimeout(() => {
          button.disabled = false
        }, 5000)
      })
    })
  }

  showMessage(msg, type = 'success') {
    const modal = document.getElementById('questionMessageModal')
    const alert = modal.querySelector(`.alert--${type}`)
    alert.innerHTML = msg
    alert.classList.remove('d-none')
    setTimeout(() => {
      alert.classList.add('d-none')
    }, 5000)
  }

  closeModal() {
    const modal = document.getElementById('questionMessageModal')
    modal.querySelector('.btn-close').click()
  }
}
