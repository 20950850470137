// Stimulus JS
import { application } from "../../controllers/application";
import StickerRequestController from "../../controllers/sticker_request_controller";

export const UserAdvertisementShowView = {

  init() {
    UserAdvertisementShowView.initStimulus()
  },

  initStimulus() {
    application.register("sticker_request", StickerRequestController)
  }

}