import { isEmpty } from 'lodash';

class FormService {
    clearInputState(input) {
        const parent = input.parentElement
        const messageElement = parent.querySelector('.feedback-message')
        const iconElement = parent.querySelector('.icon')
        messageElement?.classList.remove('valid-feedback', 'invalid-feedback')
        messageElement?.classList.add('d-none')
        iconElement?.classList.remove('icon-warning', 'bg-danger')
        iconElement?.classList.add('d-none')
        input.classList.remove('is-valid', 'is-invalid', 'has-icon')
    }

    setInputAsValid(input, message = '') {
        const parent = input.parentElement
        const messageElement = parent.querySelector('.feedback-message')
        const iconElement = parent.querySelector('.icon')
        messageElement?.classList.add('valid-feedback')
        messageElement?.classList.remove('d-none')
        iconElement?.classList.add('icon-check-circle', 'bg-success')
        iconElement?.classList.remove('d-none')
        input.classList.add('is-valid', 'has-icon')
        if(!isEmpty(message)) messageElement.innerHTML = message
    }

    setInputAsInvalid(input, message = '') {
        const parent = input.parentElement
        const messageElement = parent.querySelector('.feedback-message')
        const iconElement = parent.querySelector('.icon')
        messageElement?.classList.add('invalid-feedback')
        messageElement?.classList.remove('d-none')
        iconElement?.classList.add('icon-warning', 'bg-danger')
        iconElement?.classList.remove('d-none')
        input.classList.add('is-invalid', 'has-icon')
        if(!isEmpty(message)) messageElement.innerHTML = message
    }
}

export default new FormService();
