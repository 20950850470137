// Rails UJS
import Rails from "@rails/ujs"
if(!window._rails_loaded) Rails.start()

// Stimulus controllers
import OfferController from "../controllers/offer_controller"
import ReportController from "../controllers/report_controller"
import QuestionController from "../controllers/question_controller"
import UserSignInController from "../controllers/user_signin_controller"
import UserPasswordController from "../controllers/user_password_controller"

// Stimulus JS
import { registerControllers } from 'stimulus-vite-helpers'
import { application } from "../controllers/application";
const controllers = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)
application.register("offer", OfferController)
application.register("report", ReportController)
application.register("question", QuestionController)
application.register("signin", UserSignInController)
application.register("password", UserPasswordController)

import '~/src/app.js'
